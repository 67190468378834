<script>
import Layout from "../../layouts/horizontal"
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import { mapGetters, mapActions } from 'vuex';


export default {
  components: {
    Layout,
    FormWizard,
    TabContent,
    WizardButton
  },
  data() {
    return {
      invalidName: false,
      title: 'Create Risk Profile',
      forcedLiquidateRiskOptions:[
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 }
      ],
      forcedLiquidateRiskOptionsSmall:[
        { text: '1 [Not at all likely]', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5 [Extremely likely]', value: 5 }
      ],
      name: 'My Risk Profile',
      model:{
      
        investmentHorizon: null,
        forcedLiquidateRisk: null,
        investmentStyle: null,
        investmentStyleBenchmark: (this.currentUser || {}).defaultBenchmark || 'SP500',
        riskAttitude: null,
        riskAbility: null,
        riskAdjustDrawdown: 2,
        riskAdjustReturn: 2,
        riskAdjustEquityfraction : 3,
        riskScenarioAttitude: null
      },
      breadcrumbs: [
        {
          text: 'Home',
          active: true,
          href: '/'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('riskprofiles', {
      riskProfiles: 'items'
    }),
    invStyleComp: {
      get: function() {
        if(this.model.investmentStyle === 4) return "A";
        if(this.model.investmentStyle === 5 && this.model.investmentStyleBenchmark === "C8 USGG10YR TR Benchmark") return "B";
        if(this.model.investmentStyle === 5 && this.model.investmentStyleBenchmark === "S&P Balanced Equity and Bond") return "C";
        return null;
      }, 
      set: function(v) {
        if(v === "A") {
          this.model.investmentStyle = 4;
          this.model.investmentStyleBenchmark = (this.currentUser || {}).defaultBenchmark || 'SP500';
        } else if(v === "B") {
          this.model.investmentStyle = 5;
          this.model.investmentStyleBenchmark = "C8 USGG10YR TR Benchmark";
        } else if(v === "C") {
          this.model.investmentStyle = 5;
          this.model.investmentStyleBenchmark = "S&P Balanced Equity and Bond";
        }
      }
    },
     ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('riskprofiles', {
        processing: 'processing'
    }),
    allowNext1() {
      var o = this.model.investmentHorizon && this.model.forcedLiquidateRisk && this.model.investmentStyle;
      if(o && this.model.investmentStyle === 5){
        return this.model.investmentStyleBenchmark;
      }
      return o;
    },
    allowNext2() {
      return this.model.riskAbility && this.model.riskAttitude;
    },
    allowNext3() {
      return this.model.riskAdjustEquityfraction && this.model.riskAdjustReturn  && this.model.riskAdjustDrawdown;
    },
    allowNext4() {
      return this.model.riskScenarioAttitude;
    },
    allowNext5() {
      return this.model.name;
    }
  },
  methods: {
    ...mapActions('riskprofiles', {
        create: 'create'
    }),
    async submit() {
      this.invalidName = false;
      const o = this.riskProfiles.find(x => x.name.toLowerCase().trim() === this.name.toLowerCase().trim());
      if(o) {
        this.invalidName = true;
        return;
      }
      await this.create({
        questionnaire: this.model,
        name: this.name
      });
      this.$router.push({name:'home'});
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
              {{ title }}
            </span>
          </h4>

          <div class="page-title-right">
            <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="">
        <div class="">
          <form-wizard color="#556ee6">
            <template slot="footer" slot-scope="props">

              <div class="wizard-footer-left">
           
                <wizard-button  v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle" class="wizard-footer-left btn btn-secondary"  :disabled="processing">Back</wizard-button>
              </div>
              
              <div class="wizard-footer-right" v-if="props.activeTabIndex === 0">
                <wizard-button @click.native="props.nextTab()" class="wizard-footer-right btn btn-primary" :style="props.fillButtonStyle" :disabled="!allowNext1">Next</wizard-button>
              </div>
              
              <div class="wizard-footer-right" v-if="props.activeTabIndex === 1">
                <wizard-button @click.native="props.nextTab()" class="wizard-footer-right btn btn-primary" :style="props.fillButtonStyle" :disabled="!allowNext2">Next</wizard-button>
              </div>

              <div class="wizard-footer-right" v-if="props.activeTabIndex === 2">
                <wizard-button @click.native="props.nextTab()" class="wizard-footer-right btn btn-primary" :style="props.fillButtonStyle" :disabled="!allowNext3">Next</wizard-button>
              </div>

              <div class="wizard-footer-right" v-if="props.activeTabIndex === 3">
                <wizard-button @click.native="submit(props.nextTab)" class="wizard-footer-right btn btn-primary" :style="props.fillButtonStyle" :disabled="processing || !name">
                  <i class="fa fa-spin fa-spinner mr-2" v-if="processing"></i> 
                  Submit
                </wizard-button>
              </div>

            </template>

            <tab-content text="1">
              <h2 class="mt-4 mb-2">Thinking about portfolio goals.</h2>
              <p class="text-muted mb-3">
                We need to ask a few questions to build an interesting portfolio.
              </p>
              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box">1</h4>
                      </span>
                      <h4 class="col">What investment period is expected?</h4>
                    </div>
                    <p class="text-muted mb-3">
                      It is usually riskier to invest with a short horizon.
                    </p>
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.investmentHorizon" :value="1">1-5 years</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.investmentHorizon" :value="2">3-7 years</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.investmentHorizon" :value="3">5-10 years</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.investmentHorizon" :value="4">10-15 years</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.investmentHorizon" :value="5">Over 15 years</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box">2</h4>
                      </span>
                      <h4 class="col">How likely is a 50% redemption over that investment horizon?</h4>
                    </div>
                    <p class="text-muted mb-3">
                      We are most interested in the chance of unexpectedly having to liquidate early.
                    </p>
                    <div class="d-none d-sm-block">
                      <span style="display:inline-block"  >
                        <b-form-radio-group
                          v-model="model.forcedLiquidateRisk"
                          :options="forcedLiquidateRiskOptions"
                          
                          button-variant="outline-secondary"
                          size="lg"
                       
                          
                          name="forcedLiquidateRisk"
                          buttons
                        ></b-form-radio-group>
                        <span style="display:block" class="small">
                          <span class="float-left">Not at all likely</span>
                          <span class="float-right">Extremely likely</span>
                        </span>
                      </span>
                    </div>
                    
                    <span style="display:inline-block" class="d-block d-sm-none">
                      <b-form-radio-group
                        stacked
                        v-model="model.forcedLiquidateRisk"
                        :options="forcedLiquidateRiskOptionsSmall"
                        
                        size="lg"
                        
                        name="forcedLiquidateRisk"
                        
                      ></b-form-radio-group>
                      
                    </span>
                  </div>
                </div>
              </div>

              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box">3</h4>
                      </span>
                      <h4 class="col">What is most important when thinking about the portfolio?</h4>
                    </div>
                    
                    <b-form-group class="mt-3">
                      <!-- <b-form-radio size="lg" class="mt-2" v-model="model.investmentStyle" :value="1">Minimise Losses  --  This means you look for portfolios that try to limit drawdowns and volatility at the expense of profit.</b-form-radio> -->
                      <!-- <b-form-radio size="lg" class="mt-2" v-model="model.investmentStyle" :value="2">Balance risk of Losses against Profit -- You look for the sweet spot between expected risk and return.</b-form-radio> -->
                      <!-- <b-form-radio size="lg" class="mt-2" v-model="model.investmentStyle" :value="3">Maximise Profit  --  This means you seek a portfolio that focuses on performance, even if there is high volatility and risk of losses.</b-form-radio> -->
                      <b-form-radio size="lg" class="mt-2" value="A"  v-model="invStyleComp">
                        Stability -- Build on a Tactical Asset Allocation model selecting for stable returns. The allocation penalises excess performance and underperformance equally.
                      </b-form-radio>
                      <b-form-radio size="lg" class="mt-2" value="B" v-model="invStyleComp">
                        Income -- Prefer a mixed allocation enabling synthetic income. The portfolio seeks to return long term government yields plus 4%.
                      </b-form-radio>
                      <b-form-radio size="lg" class="mt-2" value="C" v-model="invStyleComp">
                        Balance -- Allow a diverse portfolio tailored to risk appetite. Depending on your risk profile, the solution will aim to track either Conservative, Moderate or Growth benchmarks that invest in equities and government bonds.
                      </b-form-radio>
                     </b-form-group> 
                  </div>
                </div>
              </div>

              <!-- <div class="card cardc8 mt-4"  style="position: relative;" v-if="model.investmentStyle === 5">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box">4</h4>
                      </span>
                      <h4 class="col">Select a Benchmark</h4>
                    </div>
                    <p class="mb-3">
                      e.g. <b> Core S P 500 UCITS ETF</b>
                    </p>
                    <input class="form-control form-control-lg" placeholder="Enter your answer" v-model="model.investmentStyleBenchmark"/>
                  </div>
                </div>
              </div> -->

            </tab-content>

            <tab-content text="2">
              <h2 class="mt-4 mb-4">Thinking about risk</h2>
              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">4</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">5</h4>
                      </span>
                      <h4 class="col">What is the attitude towards investment risk?</h4>
                    </div>
                  
                    <b-form-group class="mt-3">
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAttitude" :value="1">Low risk investments with secure income wanted.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAttitude" :value="2">Moderate risk for more capital gain acceptable.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAttitude" :value="3">Slightly higher risk to achieve higher yield acceptable.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAttitude" :value="4">Higher risks accepted to seek high capital gains.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAttitude" :value="5">Any risks accepted to obtain highest possible capital gains.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">5</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">6</h4>
                      </span>
                      <h4 class="col">What is the ability to take investment risk?</h4>
                    </div>
                    <p class="text-muted mb-3">
                      When managing this portfolio, what drawdown is acceptable before you would be required to take action.
                    </p>
                  
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAbility" :value="1">Drawdown up to 10% before I would have to take action.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAbility" :value="2">Drawdown up to 20% before I would have to take action.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAbility" :value="3">Drawdown up to 40% before I would have to take action.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </tab-content>

            <!-- <tab-content text="3">
              <h2 class="mt-4 mb-4">Adjusting portfolio requirements</h2>
              <p class="text-muted">
                This allows final adjustments to criteria from our defaults - for example, if you are repeating a study.
              </p>
              <p class="text-muted mb-3">
                Increasing risk may not be achieved if other constraints would be broken.
                It is possible to select requirements that are not possible individually or in combination with other constraints.
              </p>
              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">6</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">7</h4>
                      </span>
                      <h4 class="col">Drawdowns</h4>
                    </div>
                    <p class="text-muted mb-3">
                      Low drawdown limits may push the portfolio to cash assets with negligible annual returns.
                    </p>
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustDrawdown" :value="1">Reduce largest expected drawdown by 5% of capital.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustDrawdown" :value="2">No changes.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustDrawdown" :value="3">Allow higher drawdowns, by an additional 5% of capital.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">7</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">8</h4>
                      </span>
                      <h4 class="col">Returns</h4>
                    </div>
                    <p class="text-muted mb-3">
                      Limiting the range of annual returns can improve portfolio stability and reduce volatility.
                    </p>
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustReturn" :value="1">Reduce annualised return target by 0.2x.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustReturn" :value="2">No changes.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustReturn" :value="3">Allow higher annualised returns, up to 1.2x higher.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">8</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">9</h4>
                      </span>
                      <h4 class="col">Equity Fraction</h4>
                    </div>
                    <p class="text-muted mb-3">
                      Allowing 100% equities in the portfolio is regarded as high risk. If the Equity limit is reached, we will force asset diversification at the expense of additional performance.
                    </p>
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustEquityfraction" :value="1">Reduce the maximum amount of equities by 20% of capital.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustEquityfraction" :value="2">No changes.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskAdjustEquityfraction" :value="3">An extra 20% of capital can be invested in equities.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

            </tab-content> -->

            <tab-content text="4">
              <h2 class="mt-4 mb-4">Finally...</h2>
              
              <div class="card cardc8 mt-4"  style="position: relative;">
                <div class="card-content m-3">
                  <div class="card-body">
                    <div class="row">
                      <span class="text-center">
                        <h4 class="q-square-box" v-if="model.investmentStyle !== 5">6</h4>
                        <h4 class="q-square-box" v-if="model.investmentStyle === 5">7</h4>
                      </span>
                      <h4 class="col">Imagine the investment yields 6% per year, but it just lost 20%. What is the reaction?</h4>
                    </div>
                    <p class="text-muted mb-3">
                      [This question is trying to double-check previous responses by making the user imagine they are experiencing a drawdown]
                    </p>
                    <b-form-group>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskScenarioAttitude" :value="1">Awful. Losing 20% is too much.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskScenarioAttitude" :value="2">Unhappy. Uncomfortable with losing 20%.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskScenarioAttitude" :value="3">Okay. It is accepted that risk is required to generate returns.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskScenarioAttitude" :value="4">Disappointed. Hoping for a higher return.</b-form-radio>
                      <b-form-radio size="lg" class="mt-2" v-model="model.riskScenarioAttitude" :value="5">Sanguine. Risk and return expectations are higher.</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </tab-content>

            <tab-content text="5">
              <div class="form-group" v-if="invalidName">
                <div class="alert alert-warning">
                  Name is not unique!
                </div>
              </div>
              <div class="form-group">
                <label>Name</label>
                <input class="form-control" v-model="name" placeholder="Enter name"/>
              </div>
            </tab-content>

            <tab-content icon="mdi mdi-check-bold">
              <div class="row">
                <div class="col-12">
                  <div class="text-center">
                    <h2 class="mt-0">
                      <i class="mdi mdi-check-all"></i>
                    </h2>
                    <h3 class="mt-0">Thank you !</h3>

                    <p class="w-75 mb-2 mx-auto">
                      Your response was submitted.
                    </p>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </Layout>
</template>